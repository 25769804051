<template>

  <b-overlay
    :show="loading"
    rounded="sm"
  >

    <b-card title="Add new configuration">
      <b-form @submit.prevent>
        <b-row>

          <b-col cols="12">
            <b-form-group
              label="Asin (separate by comma : , for multiple asins) "
              label-for="v-asin"
            >
              <b-form-input
                id="v-asin"
                v-model="competitorAsin.asin"
                placeholder="asin1,asin2,asin3...."
                required
              />
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group
              label="Label"
              label-for="v-label"
            >
              <b-form-input
                id="v-label"
                v-model="competitorAsin.label"
                placeholder="Label"
              />
            </b-form-group>

          </b-col>

          <!-- submit and reset -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="loading"
              @click="saveAsin"
            >
              Add
            </b-button>

            <router-link :to="{name:'search-terms'}">

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Back
              </b-button>

            </router-link>

          </b-col>
        </b-row>
      </b-form>

    </b-card>

  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormSelect, BFormCheckbox, BForm, BButton,
  BOverlay,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BOverlay,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      competitorAsin: {},
    }
  },
  methods: {
    saveAsin() {
      this.loading = true

      this.$store.dispatch('competitorAsin/create', this.competitorAsin)
        .then(result => {
          this.$router.push({ name: 'search-terms' })
        },
        error => {
          this.showToast('Error', error.response?.data?.message || error?.message || 'Error while saving', 'danger')
        }).finally(() => {
          this.loading = false
        })
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          text,
          variant,
        },
      })
    },
  },
}
</script>
